@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.sa-list-item
  background: $white
  box-shadow: 0 0 14px 0 rgba(222,231,231,0.55)
  border-radius: 4px
  margin-bottom: 24px
  @media (min-width: $tablet)
    margin-bottom: 32px

  &__top-section
    display: flex
    flex-direction: column
    @media (min-width: $tablet)
      flex-direction: row
      align-items: center
      justify-content: space-between
      padding: 16px 24px
      border-bottom: 1px solid $bg-badge-rented-out
    h2
      @include fontSLH(21px, 32px)
      padding: 16px
      border-bottom: 1px solid $bg-badge-rented-out
      margin-bottom: 0
      @media (min-width: $tablet)
        padding: 0
        border-bottom: none
      @media (min-width: $desktop-md)
        font-size: 24px

  &__manage-buttons
    padding: 16px 16px 0
    display: flex
    align-self: flex-start
    @media (min-width: $tablet)
      padding: 0

  &__edit-button
    text-decoration: none
    margin-right: 32px
    color: $green
    &:hover
      text-decoration: none
      color: $green-hover
      .sa-list-item__edit-icon
        background-image: url("../../../src/images/icons/edit-hover.svg")
    @media (min-width: $tablet)
      margin-right: 48px

  &__delete-button
    color: $light-red
    &:hover
      color: #CF1212
      .sa-list-item__delete-icon
        background-image: url("../../../src/images/icons/delete-hover.svg")

  &__edit-button, &__delete-button
    @include fontSLH(16px, 24px)
    display: flex
    flex-direction: column
    align-items: center
    cursor: pointer
    span
      display: block

  &__edit-icon, &__delete-icon
    height: 18px
    margin-bottom: 4px

  &__edit-icon
    width: 18px
    background-image: url("../../../src/images/icons/edit.svg")

  &__delete-icon
    width: 14px
    background-image: url("../../../src/images/icons/delete.svg")

  &__content
    padding: 16px
    @media (min-width: $tablet)
      padding: 24px

  &__subtitle
    margin-bottom: 24px

  &__configuration
    margin-bottom: 24px
    h3
      @include fontSLH(16px, 24px)
      margin-bottom: 8px
    p
      margin-bottom: 0
    @media (min-width: $tablet)
      &:last-child
        margin-bottom: 0

  &__configuration-wrapper
    &:last-child .sa-list-item__configuration
      margin-bottom: 0
