@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.search-agent
  &__area-separator
    @include fontSLH(16px, 24px)
    font-family: MuseoSans, san-serif
    margin: 8px 0

  &__area-search
    margin-bottom: 8px

    .dropdown-selector
      padding: 16px 0
      border: 1px solid $bg-badge-rented-out
      border-radius: 4px
      box-shadow: 0 0 14px 0 rgba(222,231,231,0.55)
      background-color: $white !important

    .dropdown-selector__trigger
      height: 32px
      margin: 0 16px

    .dropdown-selector__placeholder
      margin: 0 !important
      input
        @include displayFlex(center)
        padding: 0 0 0 13px
        height: 100%
        font-size: 16px

    .dropdown-selector__action-icon
      width: 24px
      min-width: 24px

    .dropdown-selector__body
      border: none !important
      overflow: visible !important

    .dropdown-selector__empty
      color: $red
      text-align: center
      padding: 0 16px
      margin-top: 16px

    .dropdown-selector__item
      padding: 0 16px !important
      &:last-child
        margin-bottom: -10px

    .lds-ellipsis
      margin-top: 16px
      margin-bottom: 0

    .dropdown-selector__item-name
      line-height: 17px !important

    .dropdown-selector__column
      padding: 6px 0 0 !important

  &__dropdown-trigger
    @include displayFlex(center, space-between)
    height: 52px
    border: 1px solid $bg-badge-rented-out
    border-radius: 4px
    background-color: $white
    padding: 0 16px
    cursor: pointer
    span
      @include fontSLH(14px, 24px)
      font-family: MuseoSans, san-serif

  &__area-navigator
    .dropdown-selector__top-block
      display: flex
      flex-direction: column
      @media (min-width: $desktop)
        flex-direction: row
        justify-content: space-between
        .dropdown-selector__select-buttons
          margin-left: 16px
    .dropdown-selector__item-checkbox
      position: relative
      top: 2px
    .dropdown-selector__item
      @include fontSLH(14px, 24px)
      min-height: 0
      padding: 0 0 16px 0 !important
      -webkit-column-break-inside: avoid
      page-break-inside: avoid
      break-inside: avoid
      position: relative
      align-items: flex-start
      &:last-child
        margin-bottom: 0
      &:hover
        background-color: $white !important
        color: $green-hover
        .dropdown-selector__item-name
          text-decoration: underline
          color: $green-hover !important
          &.checkbox-label
            text-decoration: none
      &--selected
        .dropdown-selector__item-icon
          @include square(16px)
          position: relative
          top: 2px
    .dropdown-selector__item-name
      line-height: 21px
    .dropdown-selector__body
      padding: 16px 16px 0
      background: $white
      @media (min-width: $tablet)
        flex-direction: column
      @media (min-width: $desktop)
        &:not(.first-page)
          width: 141%
    .dropdown-selector__column
      padding: 0
      position: relative
      @media (min-width: $tablet)
        column-count: 2
        column-gap: 26px
        &:before
          content: ''
          position: absolute
          height: calc(100% + 16px)
          border-right: 1px solid $bg-badge-rented-out
          display: block
          left: 50%
          bottom: -16px
        &.first-page:before
          height: calc(100% + 32px)
        @media (min-width: $desktop)
          &:not(.first-page)
            column-count: 3
            &:before
              left: 32%
            &:after
              content: ''
              position: absolute
              height: calc(100% + 16px)
              border-right: 1px solid $bg-badge-rented-out
              display: block
              right: 33.3%
              bottom: -16px
