@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.search-agent
  &__overview-description
    @media (min-width: $tablet)
      display: none
    &.mobile-hidden
      display: none
      @media (min-width: $tablet)
        display: block

  &__overview-wrapper
    z-index: initial


  &__bottom-wrapper
    background: $white
    padding-top: 8px
    padding-bottom: 32px

  &__overview-container
    background-color: $white
    box-shadow: 0 0 14px 0 rgba(222,231,231,0.55)
    position: relative
    z-index: 4
    border-radius: 4px
    @media (max-width: $screen-xs-max)
      position: fixed
      bottom: 0
      left: 0
      right: 0

  &__overview-header
    @include displayFlex(center, space-between)
    padding: 15px 24px
    background-color: $white
    color: $default
    border-bottom: 1px solid #E0E2E2
    @media (min-width: $tablet)
      border-top-left-radius: 4px
      border-top-right-radius: 4px
    &:hover
      cursor: pointer
    h2
      margin: 0
      @media (min-width: $tablet)
        font-size: 21px
      &:hover
        cursor: initial
    svg
      fill: $white

  &__overview-body
    padding: 20px
    @media (min-width: $tablet)
      padding: 24px
    h3
      padding-bottom: 10px
      margin: 0
    p
      margin: 0 0 5px !important
      padding: 0

  &__overview-section
    padding-top: 19px
    p
      margin-bottom: 5px !important
    &:last-child p
      margin-bottom: 0
    h3
      @include fontSLH(16px, 24px)

  &__overview-user-item
    display: flex
    margin-bottom: 3px

  &__overview-user-field
    @include fontSLH(16px, 24px)
    font-weight: 900
    width: 90px
    min-width: 90px

  &__overview-user-info
    @include fontSLH(16px, 24px)
    font-family: MuseoSans, san-serif
    word-break: break-all

  &__overview-submit
    margin-top: 32px
    &.mobile
      margin-top: 24px
    .b-button
      height: 48px
      border-radius: 4px
      text-transform: none
      @media (min-width: $tablet)
        width: 328px
    .search-edit-button
      width: 100%

  &__overview-mobile
    @media (min-width: $tablet)
      display: none
    .search-agent__overview-container
      display: flex
      flex-direction: column
      transition: top .3s ease

    .search-agent__overview-header
      background-color: $bg-charcoal
      border-bottom: none
      height: 42px
      color: white
      h2
        @include fontSLH(16px, 24px)
      span
        display: flex

    .search-agent__overview-body
      flex-grow: 1
      height: 0
      padding: 0
      overflow: hidden
      transition: height .3s ease
      h3
        line-height: 24px
        padding-bottom: 8px

    .search-agent__overview-opened
      top: 0
      z-index: 10000
      .search-agent__overview-body
        overflow: auto
        padding: 16px
        height: calc(100vh - 48px)

  &__header-block
    display: flex
    align-items: center
    position: relative
    svg
      fill: $green
      cursor: pointer
      margin-left: 8px
      position: relative
      top: 1px

      &:hover
        color: $green-hover
        & ~ .my-tooltip
          font-family: MuseoSans, sans-serif
          visibility: visible
          opacity: 1
          left: 0
          transform: translateX(0)
          top: auto
          white-space: unset !important
          bottom: 32px
          padding: 8px 16px
          &:before, &:after
            display: none
          @media (min-width: $tablet)
            max-width: 300px
        &:before, &:after
          content: ""
          width: 0
          height: 0
          position: absolute
        &:before
          border-left: 10px solid transparent
          border-right: 10px solid transparent
          bottom: -8px
          left: 50%
          margin-left: -10px
        &:after
          border-top-color: #222
          border-top-style: solid
          border-top-width: 6px
          border-left: 8px solid transparent
          border-right: 8px solid transparent
          bottom: 20px
          left: 50%
          margin-left: -8px

.sticky-outer-wrapper
  display: none
  @media (min-width: $tablet)
    display: block
