@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.search-agent
  &__ad-container
    padding: 32px 0
    background-color: $white
    background-position: bottom
    background-repeat: no-repeat
    margin-bottom: -1px
    background-size: contain
    @media (min-width: $desktop-md)
      padding: 56px 0

  h1.search-agent__ad-title
    @include fontSLH(24px, 30px)
    margin-top: 0
    margin-bottom: 16px
    @media (min-width: $desktop-md)
      @include fontSLH(30px, 44px)

  &__ad-title-edit
    @include fontSLH(16px, 24px)
    max-width: 450px
    font-family: MuseoSans, Helvetica, Arial, sans-serif
    @media (max-width: $screen-xs-max)
      margin-bottom: 32px
      div
        display: inline

  &__ad-list
    list-style: none
    padding: 0
    margin-bottom: 32px
    @media (min-width: $tablet)
      margin-bottom: 0

    li
      display: flex

    li + li
      margin-top: 10px

    svg
      fill: $green
      margin-right: 7px

    span
      @include fontSLH(16px, 24px)
      font-family: MuseoSans, sans-serif

  &__ad-logo-wrapper
    display: flex
    justify-content: center
    @media (min-width: $tablet)
      align-items: center

  &__ad-logo
    @include boxSize(auto, 112px)
    @media (max-width: $desktop-md)
      height: 190px
    @media (min-width: $desktop-md) and (max-width: $screen-md-max)
      max-width: 282.56px
