@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.manage-sa
  font-family: MuseoSans, sans-serif

  &__top-section
    display: flex
    flex-direction: column
    padding: 32px 0
    @media (min-width: $tablet)
      flex-direction: row
      justify-content: space-between
      align-items: center
    img
      @include boxSize(147px, 98px)
      align-self: center

  &__headline
    h1
      @include fontSLH(24px, 32px)
      margin-bottom: 8px
      @media (min-width: $tablet)
        margin-bottom: 16px
      @media (min-width: $desktop-md)
        @include fontSLH(30px, 44px)

    p
      margin-bottom: 24px
      @media (min-width: $tablet)
        margin-bottom: 0

  &__agents-list
    padding: 24px 0 64px
    background: $grey-bg
    @media (min-width: $desktop-md)
      padding-top: 32px

  &__agents-list-title
    padding-bottom: 24px
    margin-bottom: 0
    @media (min-width: $tablet)
      text-align: right
    @media (min-width: $desktop-md)
      padding-bottom: 32px

  &__pagination-wrapper
    display: flex
    justify-content: center
    position: relative
    margin-top: 8px
    .pagination
      display: flex
      align-items: center
      list-style: none
      margin: 0
      padding: 0
      li
        a
          @include fontSLH(16px, 24px)
          font-weight: 300
          text-decoration: none
          color: $default
          padding: 5px 10px
          cursor: pointer
          outline: none
          display: block
          user-select: none
          &:hover
            font-weight: 900
      .active
        a
          font-weight: 500
          outline: none
      .next, .previous
        a
          padding: 0
          display: flex
        .right, .left
          font-size: 32px
        .right
          margin-left: 10px
        .left
          margin-right: 10px
      .disabled
        display: none

  &__options-links-wrapper
    padding-top: 64px
    padding-bottom: 64px
    .row
      @include displayFlex(stretch)

  &__empty-results
    margin-top: 8px
    margin-bottom: -16px
    @media (min-width: $tablet)
      margin-bottom: 0
      margin-top: 40px
    @media (min-width: $desktop-md)
      margin-top: 32px

  &__empty-results-wrapper
    text-align: center
    display: flex
    flex-direction: column
    align-items: center
    h2
      margin-bottom: 16px
    p
      margin-bottom: 24px
    p, a
      @media (max-width: $screen-xs-max)
        margin-left: 16px
        margin-right: 16px

  &__create-sa-button
    @include displayFlex(center, space-between)
    @include fontSLH(16px, 24px)
    font-family: MuseoSans, sans-serif
    font-weight: 900
    background: $green
    color: $white
    text-decoration: none
    border-radius: 4px
    min-height: 48px
    padding: 0 24px
    text-transform: capitalize
    user-select: none
    width: calc(100% - 36px)
    &:hover
      background: $green-hover
      color: $white
      text-decoration: none
    @media (min-width: $tablet)
      width: 281px
      padding: 0 32px
    svg
      fill: $white

  h1, h2, h3
    font-family: MuseoSans, sans-serif

.smoothie-spinner
  @include square(60px)
  margin: 0 auto
  background: $green
  border-radius: 50%
  animation: boing .8s linear infinite
  position: relative
  top: calc(50% - 35px)
  &:before
    @include beforeEmptySquare(56px, $light-green, calc(50% - 28px), $left: calc(50% - 28px))
    display: block
    border-radius: 50%
    animation: boing-inner .8s linear infinite

  &:after
    @include beforeEmptySquare(52px, $white, calc(50% - 26px), $left: calc(50% - 26px))
    display: block
    border-radius: 50%
    animation: boing-inner-2 .8s linear infinite

@keyframes boing
  0%
    transform: scale(1)
  25%
    transform: scale(.75)
  100%
    transform: scale(1)

@keyframes boing-inner
  0%
    transform: scale(1)
  25%
    transform: scale(.6)
  100%
    transform: scale(1)

@keyframes boing-inner-2
  0%
    transform: scale(1)
  10%
    transform: scale(.1)
  100%
    transform: scale(1)

