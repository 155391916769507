// MOBILE_FIRST
@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'

.input-range
  @include fullWidthSize(1rem)
  position: relative
  &__container
    margin: 15px 0 30px 0

  &__slider
    @include boxSize(20px, 15px)
    @include positionAbsolute(-15px)
    appearance: none
    background: $white
    border: 1px solid $border-form-input
    border-bottom: 0
    border-radius: 3px 3px 0 0
    cursor: pointer
    display: block
    outline: none
    transition: transform 0s ease-out, box-shadow 0s ease-out
    margin-left: -10px
    box-shadow: 0 1px 1px 0 rgba(49,60,63,0.25)

    &::after
      @include beforeEmptySquare(16px, $white, 6px, 1px)
      border-radius: 0 4px 3px 4px
      box-shadow: 2px 2px 1px -1px rgba(49, 60, 63, 0.15)
      z-index: 4
      transform: rotate(45deg)
      border: 1px solid $border-form-input
      border-top: none
      border-left: none

  &--disabled .input-range__slider
    background: #cccccc
    border: 1px solid #cccccc
    box-shadow: none
    transform: none

  &__slider-container
    transition: left 0s ease-out

  &__label-container
    display: none

  &__label
    display: flex
    justify-content: space-between
    margin-bottom: 20px

  &__inputs
    margin-top: 20px
    display: flex
    justify-content: space-between

    .form-group
      display: inline-flex
      align-items: center

    input
      border-radius: 0
      display: inline-block
      margin-right: 10px
      text-align: right
      padding-right: 10px
      width: 80px
      @media (min-width: $tablet)
        width: 100px
      @media (min-width: $desktop)
        padding-right: 0
      &:not(.outline-none)
        &:focus
          border-color: $green !important

    @media (max-width: $screen-xs-max)
      span
        font-size: 12px

  &__track
    background: $white
    border-radius: 8px
    cursor: pointer
    display: block
    height: 10px
    box-shadow: inset 0 1px 4px 0 rgba(49,60,63,0.25), 0 1px 3px 0 rgba(0,0,0,0.15)
    position: relative
    transition: left 0s ease-out, width 0s ease-out

  &--disabled .input-range__track
    background: #eeeeee

  &__track--background
    @include positionAbsolute(50%, 0, false, 0)
    margin: 0 10px
    touch-action: none

  &__track--active
    background: $green

  &__input-label
    text-transform: capitalize
