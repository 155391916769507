@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.contact-form
  .sign-up-form__floating-label
    top: 15px
    color: $default
    left: 16px
    position: absolute
    transition: all 0.2s
    &.active
      font-size: 12px
      font-weight: 300
      -webkit-transform: translate(0, -12px)
      transform: translate(0, -12px)

  input:focus
    padding-top: 15px
    & ~ .sign-up-form__floating-label
      font-size: 12px
      font-weight: 300
      -webkit-transform: translate(0, -12px)
      transform: translate(0, -12px)

.search-agent
  .form-group.search-agent__form-group
    margin-bottom: 8px
    &:last-child
      margin-bottom: 23px
    &.search-agent-error
      .search-agent__input
        border-color: $red !important
      &:before
        color: $red

  &__input
    width: 100%
    font-size: 14px
    padding: 0 16px
    border: 1px solid #e0e2e2
    box-shadow: none !important
    outline: none !important
    height: 52px
    border-radius: 4px
    font-family: MuseoSans, sans-serif

  &__input.active
    padding-top: 15px

  &__label
    max-width: 100%
    width: 100%
    margin: 0
    &:focus
      padding-top: 15px
