@import '../../src/stylesheets/variables'

.my-tooltip
  font-size: 13px !important
  line-height: 20px
  font-weight: 100
  padding: 8px 21px
  border-radius: 5px
  background: #222
  text-align: center
  display: inline-block
  opacity: 0
  position: absolute
  pointer-events: none
  transition: opacity 0.3s ease-out
  visibility: hidden
  z-index: 999
  color: $white
  top: -50px
  left: 50%
  transform: translateX(-50%)
  white-space: nowrap !important
  &:before, &:after
    content: ""
    width: 0
    height: 0
    position: absolute
  &:before
    border-left: 10px solid transparent
    border-right: 10px solid transparent
    bottom: -8px
    left: 50%
    margin-left: -10px
  &:after
    border-top-color: #222
    border-top-style: solid
    border-top-width: 6px
    border-left: 8px solid transparent
    border-right: 8px solid transparent
    bottom: -6px
    left: 50%
    margin-left: -8px
