@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'

.ReactModalPortal
  .ReactModal__Overlay
    .data-protection-modal
      position: relative
      &.ReactModal__Content
        width: calc(100% - 32px)
        margin-bottom: 16px
        @media (max-width: 767px)
          top: 0 !important
          transform: translate(-50%, 0) !important
        @media (max-height: 767px)
          top: 0 !important
          transform: translate(-50%, 0) !important
        @media (min-width: $tablet)
          width: 720px
          min-width: 720px
        @media (min-width: $desktop-md)
          width: 635px
          min-width: 635px
        @media (min-width: $desktop)
          width: 784px
          min-width: 784px

      &.ReactModal__Content--after-open
        @media (max-width: 767px)
          top: 20px !important
        @media (max-height: 767px)
          top: 20px !important
        @media (max-height: 767px) and (min-width: $desktop-md)
          top: 40px !important

      .modal-close-btn
        @include closeBtnRight(16px, 16px)
        @media (min-width: $tablet)
          top: 24px
          right: 24px
        @media (min-width: $desktop-md)
          top: 32px
          right: 32px

      .modal-header
        padding: 24px 40px 24px 16px
        border-bottom: none
        @media (min-width: $tablet)
          padding: 24px
        @media (min-width: $desktop-md)
          padding: 32px
        h2
          @include fontSLH(21px, 32px)
          margin: 0
          @media (min-width: $desktop-md)
            font-size: 24px

      .modal-body
        padding: 0 16px 24px
        @media (min-width: $tablet)
          padding: 0 24px 24px
        @media (min-width: $desktop-md)
          padding: 0 32px 32px
        p
          margin-bottom: 16px
        &__content ul
          padding-left: 32px
          margin-bottom: 16px
        &__content, p
          font-family: MuseoSans, san-serif
          @include fontSLH(16px, 24px)

      .modal-body__policy-link
        display: flex
        margin-top: 16px
        a
          margin-left: 5px
          text-decoration: underline
        p
          margin-bottom: 0
