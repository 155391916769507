@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.search-agent
  .spinner-wrapper
    padding: 56px 0

  .radio
    @include square(16px)
    box-shadow: none
    vertical-align: inherit
    border: 3px solid $white

  .radio__wrapper
    display: inline-flex
    align-items: center
    label
      @include fontSLH(16px, 24px)
      margin: 0
    .radio__checkbox-wrapper
      height: 20px
      margin-right: 16px
      border: 2px solid $gull-grey-text
      border-radius: 100%
      &.active
        border-color: $green
    &.range
      margin-right: 24px
      + .radio__wrapper
        margin: 0

  h2
    @media (min-width: $tablet)
      @include fontSLH(24px, 32px)

  h3
    @include fontSLH(16px, 24px)

  p
    @include fontSLH(16px, 24px)
    font-family: MuseoSans, sans-serif
    margin-bottom: 0

  .input-range__container
    margin: 4px 0 32px 0

  .input-range__label
    line-height: 24px
    margin-bottom: 12px

  .input-range__dimension,
  .input-range__label,
  .input-range__inputs input
    font-family: MuseoSans, sans-serif
    color: $default
    font-size: 14px
    @media (min-width: 1200px)
      padding-right: 8px

  .input-range__track
    box-shadow: none

  .input-range__inputs
    .form-group
      margin-bottom: 0
    .form-control
      @include boxSize(100px, 40px)
      border: 1px solid #E8EAEA
      border-radius: 4px
      &:focus-visible
        outline: none

  .input-range__dimension
    font-size: 14px

  .input-range__slider
    height: 13px
    &:after
      top: 4px
      border-radius: 0 4px 3px 3.5px

  &__page-wrapper
    background-color: $grey-bg

  // MODULES ==========================>

  &__header-block
    margin-bottom: 16px
    + p
      margin-bottom: 16px

  &__required-block
    &::after
      content: '*'
      color: $green
      font-weight: 300
      margin-left: 5px

  // SEARCH PAGE CONFIGURATIONS ==========================>

  &__error
    @include font(12px, $red, false, 300)
    margin-top: -10px
    margin-bottom: 5px

  &__config-container
    min-height: 100vh
    margin-top: 32px
    padding-bottom: 32px
    @media (min-width: $desktop-md)
      margin-top: 56px
      padding-bottom: 56px

  &__config-block
    margin-top: 32px

  &__unsubscribe-action
    text-decoration: underline
    line-height: 14px
    color: $green
    &:hover
      cursor: pointer

  // DROPDOWN ================================>

  .dropdown-selector
    border-radius: 4px
    background-color: transparent

    &__item
      padding: 0 16px
      &:hover
        background-color: $border-light-grey
        .dropdown-selector__item-name
          color: $default
      &--selected .dropdown-selector__item-icon
        @include square(16px)

    &__item-checkbox
      @include square(16px)

    &__item-name
      font-family: MuseoSans, san-serif

    &__column
      padding: 8px 0
      background-color: $white
      .dropdown-selector__item + .dropdown-selector__item
        border-top: none

    &__body
      border: 1px solid $bg-badge-rented-out
      border-radius: 4px
      border-top: 1px solid $bg-badge-rented-out
      overflow: hidden

    &__item-icon
      margin-right: 8px
      svg.complex-selector-check
        top: -6px

    &__action-icon--colored
      background-color: transparent
      color: $default

    &__trigger
      border: 1px solid $bg-badge-rented-out
      border-radius: 4px
      padding-right: 7px
      &--active
        background-color: $white
        .dropdown-selector__placeholder
          color: $default

    &__placeholder
      font-family: MuseoSans, san-serif
      margin: 16px 0 16px 16px

    &__select-buttons
      display: flex
      margin-bottom: 24px
      .dropdown-selector__button
        @include fontSLH(14px, 21px)
        font-family: MuseoSans, san-serif
        background-color: $white
        margin-right: 32px
        color: $default
        padding: 0
        height: auto
        white-space: nowrap
        user-select: none
        &:last-child
          margin-right: 0
        &:hover
          text-decoration: underline
        &.select-all:hover
          color: $green-hover
        &.deselect-all:hover
          color: $red

    &__breadcrumbs-wrapper
      @include displayFlex(flex-start)
      margin-bottom: 12px

    &__breadcrumbs
      @include displayFlex(center)
      flex-wrap: wrap

    &__breadcrumbs-link
      @include displayFlex(center)
      color: #757575
      font-family: MuseoSans, sans-serif
      margin-bottom: 4px
      span
        @include font(12px, $default, 20px)
        cursor: pointer
        display: block
        &:hover
          text-decoration: underline
          color: $green
      svg
        fill: $gull-grey-text
        margin: 0 3px
      &:last-child
        span
          pointer-events: none
          cursor: none
          color: $gull-grey-text
        svg
          display: none

    &__back-btn
      @include displayFlex(center)
      position: relative
      cursor: pointer
      margin-right: 16px
      span
        @include font(12px, $default, 20px, bold)
        font-family: MuseoSans, sans-serif
      svg
        margin-right: 5px
      &:hover
        span
          text-decoration: underline
          color: $green-hover
        svg
          fill: $green-hover
          text-decoration: none

  // EDIT-MODE ===============================>

  &--edit-mode

    .search-agent__ad-logo
      height: 132px

    &.search-agent__ad-container
      min-height: 220px

      @media (max-width: $screen-xs-max)
        min-height: 180px

    .search-agent__config-area
      margin-top: 0

    .search-agent__overview-submit
      @media (max-width: $screen-xs-max)
        flex-direction: column

      @media (max-width: $screen-sm-max)
        flex-direction: column
        align-items: flex-start

      .search-agent__unsubscribe-action
        @include fontSLH(16px, 24px)
        margin-right: 20px
        color: $black
        font-family: MuseoSans, Helvetica, Arial, sans-serif
        margin-top: 24px
        svg
          margin-right: 8px
          width: 16px
          height: 16px


// RADIO ==========================>

.radio
  @include square(18px)
  border-radius: 50%
  background-color: $white
  border: 5px solid $white
  display: inline-block
  vertical-align: middle
  box-shadow: 0 0 1px rgba(0,0,0,0.6)

  ~ label
    margin: 0 0 0 15px
    vertical-align: middle

  &__active
    background-color: $green

  &__wrapper
    display: inline-block
    margin: 10px 0

    &:hover
      cursor: pointer

    + .radio__wrapper
      margin-left: 24px

// TAGS ==========================>

.tags__list
  list-style-type: none
  margin: 0
  padding: 0
  display: flex
  flex-wrap: wrap
  align-items: baseline
  &:hover
    cursor: pointer

.tag
  background-color: $green
  color: $white
  padding: 2px 5px 2px 10px
  border-radius: 6px
  margin: 0 5px 5px 0
  display: flex
  align-items: center
  transition: .1s background-color linear
  position: relative
  font-weight: 300
  &:hover
    background-color: $green-hover
    .tag__hover-hint
      opacity: 1
      visibility: visible
    .tag__title
      opacity: 0
      visibility: hidden
  svg
    fill: $white

  &__hover-hint
    @include positionAbsolute(0, $left: 0)
    opacity: 0
    margin: 2px 10px
    visibility: hidden

  &__title
    visibility: visible
    margin-right: 5px

.flex-end
  justify-content: flex-end

.upper
  text-transform: uppercase

.svg-icon
  @include square(20px)
  display: inline-block

.data-protection-link
  margin-top: 18px
  cursor: pointer
  display: flex
  align-items: center
  color: $green
  &:hover
    .info-label
      color: $green-hover
  svg
    fill: $green
    margin-right: 8px
  span
    font-size: 12px
    text-decoration: underline
    font-weight: 300

@media (max-width: $screen-s)
  .ReactModal__Overlay--after-open
    padding-bottom: 60px !important

.sticky-inner-wrapper
  padding-bottom: 32px
  @media (min-width: $desktop-md)
    padding-bottom: 56px

.sticky-outer-wrapper.active .sticky-inner-wrapper
  margin-top: 20px

.dropdown-selector__action-icon--colored
  pointer-events: none

.smoothie-spinner
  @include square(60px)
  margin: 0 auto
  background: $green
  border-radius: 50%
  animation: boing .8s linear infinite
  position: relative
  top: calc(50% - 35px)
  &:before
    @include beforeEmptySquare(56px, $light-green, calc(50% - 28px), $left: calc(50% - 28px))
    display: block
    border-radius: 50%
    animation: boing-inner .8s linear infinite

  &:after
    @include beforeEmptySquare(52px, $white, calc(50% - 26px), $left: calc(50% - 26px))
    display: block
    border-radius: 50%
    animation: boing-inner-2 .8s linear infinite

@keyframes boing
  0%
    transform: scale(1)
  25%
    transform: scale(.75)
  100%
    transform: scale(1)

@keyframes boing-inner
  0%
    transform: scale(1)
  25%
    transform: scale(.6)
  100%
    transform: scale(1)

@keyframes boing-inner-2
  0%
    transform: scale(1)
  10%
    transform: scale(.1)
  100%
    transform: scale(1)
