@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/modules/buttons'
@import '../../src/stylesheets/mixins'


.error-page
  &__header-container
    position: relative
    text-align: center
    padding-top: 32px
    @media (min-width: $tablet)
      padding-top: 75px
    h1
      @include fontSLH(24px, 30px)
      margin-bottom: 16px
      @media (min-width: $tablet)
        margin-bottom: 24px
      @media (min-width: $desktop-md)
        @include fontSLH(30px, 44px)
    h2
      @include fontSLH(21px, 32px)
      margin-bottom: 16px
      &.error-page__subheading-500
        margin-bottom: 24px
        @media (min-width: $desktop-md)
          margin-bottom: 16px
      @media (min-width: $desktop-md)
        @include fontSLH(24px, 32px)
    p
      @include fontSLH(16px, 24px)

  &__header-text
    max-width: 570px
    margin: 0 auto
    &--500
      margin-bottom: 24px

  &__main-container
    text-align: center
    margin-top: 64px
    margin-bottom: 32px
    h2
      @include fontSLH(21px, 32px)
      @media (min-width: $desktop-md)
        font-size: 24px

  &__server-error-links
    margin-bottom: 64px
    .return-front-page
      text-decoration: underline
    span
      color: #77bc1f
      text-decoration: underline
      cursor: pointer

  &__actions
    @include displayFlex(stretch, center)
    @media (min-width: $desktop-md)
      justify-content: flex-start

  &__action-icon
    @include fullWidthSize(139px)
    background-position: top center
    background-size: contain
    background-repeat: no-repeat
    margin-bottom: 16px
    @media (min-width: $desktop-md)
      height: 167px

  &__action
    display: flex
    flex-direction: column
    margin-bottom: 64px
    @media (min-width: $tablet)
      align-items: center
    h3
      margin-bottom: 0

  &__action-body
    padding: 0 16px
    flex-grow: 1
    text-align: center
    @media (min-width: $tablet)
      padding: 0
      max-width: 309px
    @media (min-width: $desktop-md)
      max-width: 100%
    @media (min-width: $tablet)
      max-width: 309px
      width: 309px
    p
      @include fontSLH(16px, 24px)
      margin-bottom: 16px
    h3
      @include fontSLH(16px, 26px)
      margin-bottom: 8px
      @media (min-width: $desktop-md)
        font-size: 18px

  &__button-wrapper
    padding: 0 16px
    @media (min-width: $tablet)
      padding: 0
      max-width: 309px

  .b-button
    @include fontSLH(16px, 19px)
    height: 48px
    padding: 0 32px
    justify-content: space-between
    border-radius: 4px
    width: 100%
    text-transform: none
    text-decoration: none
    @media (min-width: $tablet)
      width: 309px
    @media (min-width: $desktop-md)
      width: 100%
      max-width: 100%
    @media (min-width: $tablet)
      max-width: 309px
      width: 309px
    svg
      margin-right: 0
      margin-bottom: 4px

  .search-agent-section
    padding: 0
    margin: 0 0 64px
