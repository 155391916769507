@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.delete-modal
  @include boxSize(100vw, 100vh)
  position: fixed
  left: 0
  top: 0
  background: rgba(0,0,0,0.5)
  z-index: 9999
  display: flex
  justify-content: center
  @media (max-height: 500px)
    align-items: center
  @media (min-width: $tablet)
    align-items: center

  &__body
    background: $white
    border-radius: 4px
    padding: 24px 16px
    margin-top: 90px
    position: relative
    @media (max-height: 500px)
      margin-top: 0
    @media (min-width: $tablet)
      margin-top: 0
      padding: 24px
    @media (min-width: $desktop-md)
      padding: 32px
    p:first-child
      margin-right: 38px
      margin-bottom: 16px
      @media (min-width: $tablet)
        max-width: 81%
    p
      margin-bottom: 24px

  &__buttons
    display: flex
    flex-direction: column-reverse
    cursor: pointer
    @media (min-width: $tablet)
      flex-direction: row
      justify-content: space-between

  &__button
    @include displayFlex(center, center)
    @include fontSLH(16px, 24px)
    font-family: MuseoSans, sans-serif
    font-weight: 900
    min-height: 48px
    width: 100%
    border-radius: 4px
    border: 1px solid $orange
    @media (min-width: $tablet)
      width: calc(50% - 8px)
    &:hover
      border: 1px solid $orange-hover
    &:focus
      outline: none
    &--cancel
      color: $orange
      background: white
      &:hover
        background: $orange-hover
        color: $white
        border: 1px solid $orange-hover
    &--confirm
      background: $orange
      color: $white
      margin-bottom: 16px
      &:hover
        background: $orange-hover
      @media (min-width: $tablet)
        margin-bottom: 0

  &__close-btn
    @include closeBtnRight(24px, 16px)
    @media (min-width: $tablet)
      right: 24px

  .row
    justify-content: center
    display: flex

