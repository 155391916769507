@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.additional-option
  &.mobile-padding
    @media (max-width: $screen-lg-md-max)
      margin-bottom: 32px

  &__wrapper
    display: flex
    flex-direction: column
    justify-content: space-between
    padding: 32px 16px
    background: $grey-bg
    border-radius: 4px
    height: 100%
    @media (min-width: $tablet)
      padding: 32px
    @media (min-width: $desktop-md)
      padding: 56px 32px
    @media (min-width: $desktop)
      padding: 56px

  &__content-wrapper
    h2
      margin-bottom: 16px
    p
      @include fontSLH(16px, 24px)
      margin-bottom: 24px
      @media (min-width: $tablet)
        margin-bottom: 16px
      @media (min-width: $desktop-md)
        margin-bottom: 24px

  &__link-button
    @include displayFlex(center, space-between)
    padding: 14px 32px
    min-height: 48px
    color: $white
    border-radius: 4px
    width: 100%
    text-decoration: none
    background: $green
    &:hover
      text-decoration: none
      color: #fff
      background: $green-hover
    @media (min-width: $tablet)
      width: 328px
      padding: 14px 24px
    @media (min-width: $desktop-md)
      padding: 14px 32px
      width: 281px

    span
      @include fontSLH(16px, 19px)
      font-family: MuseoSans, san-serif
      font-weight: 900
      display: block
    svg
      fill: $white
      margin-left: 16px
    &--orange
      background: $orange
      &:hover
        background: $orange-hover
    &--green
      background: $green
      &:hover
        background: $green-hover


  &__button-wrapper
    display: flex
    align-items: center
    justify-content: space-between
    @media (max-width: $mobile)
      flex-direction: column
    @media (max-width: $desktop-md)
      justify-content: flex-start


  &.inline
    @media (min-width: $desktop-md)
      .additional-option__wrapper
        flex-direction: row
        padding: 56px 81px
        align-items: flex-end
      .additional-option__content-wrapper p
        margin-bottom: 0
      .additional-option__button-wrapper
        margin-left: 16px
    @media (min-width: $desktop)
      .additional-option__wrapper
        padding: 56px 100px
