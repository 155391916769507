// MOBILE-FIRST
@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'


.dropdown-selector
  background-color: white

  + .dropdown-selector
    margin-top: 20px

  &__empty
    padding: 10px
    color: $inactive

  &__placeholder
    @include truncate(100%)
    margin: 10px 0 10px 10px

  &__trigger
    @include font(14px, $default, 18px)
    @include displayFlex(stretch, space-between)
    border-radius: 0
    background-color: $white
    border: 1px solid $link-water
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s
    &:hover
      cursor: pointer
    &--active
      background-color: $green
      .dropdown-selector__placeholder
        color: white

  &__search
    &:hover
      cursor: initial
    svg
      color: $inactive
    input
      border: none
      padding: 11px 0 11px 11px
      width: 100%
      &:focus
        outline: none
    .dropdown-selector__placeholder
      margin: 0

  &__action-icon
    @include displayFlex(center, center)
    width: 40px
    min-width: 40px
    color: $inactive
    cursor: pointer
    &.search svg
      fill: $inactive

  &__action-icon--colored
    background-color: $green
    color: $white
    margin-left: 10px

  &__header
    @include displayFlex(center, space-between)
    background-color: white
    border: 1px solid $link-water
    padding: 0 10px
    height: 41px

    + .dropdown-selector__header
      border-top: none
      border-bottom: 1px solid $link-water

  &__header--colored
    background-color: $green
    color: $white

  &__header-back
    svg
      margin-right: 10px
    &:hover
      cursor: pointer

  &__body
    border: 1px solid $link-water
    border-top: none
    display: flex
    flex-direction: column
    @media (min-width: $tablet)
      flex-direction: row

  &__column
    flex-grow: 1
    width: 100%
    + .dropdown-selector__column
      border-left: 0
      @media (min-width: $tablet)
        border-left: 1px solid $bg-badge-rented-out
    .dropdown-selector__item + .dropdown-selector__item
      border-top: 1px solid rgba(206, 212, 218, 0.1)

  &__item
    @include displayFlex(center, flex-start)
    min-height: 35px
    background-color: $white
    padding: 0 10px
    @media (min-width: $screen-md)
      &:hover
        cursor: pointer
        background-color: $green
        color: $white
        svg.arrow
          fill: $green

  &__item-icon
    margin-right: 10px
    svg
      fill: $white
      top: -5px
      left: -1px
      position: relative

  &__item--selected
    .dropdown-selector__item-icon
      width: 18px
      height: 18px
      border: 1px solid $green
      border-radius: 2px
      background-color: $green

  &__item-checkbox
    @include square(18px)
    border: 1px solid $borders
    border-radius: 2px
    background-color: $white
    svg
      fill: $white

  &__button
    background-color: $default
    border-radius: 6px
    padding: 7px 24px
    color: $white
    height: 30px
    line-height: initial
    &:hover
      cursor: pointer

  &__item-name
    flex-grow: 1


// LOADER
.lds-ellipsis
  @include boxSize(36px, 7px)
  display: inline-block
  position: relative
  margin: 17px auto

  div
    @include positionAbsolute(0)
    @include square(6px)
    border-radius: 50%
    background: $inactive
    animation-timing-function: cubic-bezier(0, 1, 1, 0)

  div:nth-child(1)
    left: 6px
    animation: lds-ellipsis1 0.6s infinite

  div:nth-child(2)
    left: 6px
    animation: lds-ellipsis2 0.6s infinite

  div:nth-child(3)
    left: 16px
    animation: lds-ellipsis2 0.6s infinite

  div:nth-child(4)
    left: 26px
    animation: lds-ellipsis3 0.6s infinite

@keyframes lds-ellipsis1
  0%
    transform: scale(0)

  100%
    transform: scale(1)

@keyframes lds-ellipsis3
  0%
    transform: scale(1)

  100%
    transform: scale(0)

@keyframes lds-ellipsis2
  0%
    transform: translate(0, 0)

  100%
    transform: translate(10px, 0)

